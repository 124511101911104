import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useMedia } from 'use-media'

// Elements
import BrandCircles from 'components/elements/Vectors/BrandCircles'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'
import Plaatjie from '@ubo/plaatjie'

const Section = styled.section`
  background-color: ${({ theme }) => theme.color.dark};

  @media (min-width: 576px) {
    height: 90vh;
    min-height: 970px;
    max-height: 970px;
  }
`

const BrandWrapper = styled.div`
  & .centerpoint {
    position: relative;
  }

  @media (max-width: 575.98px) {
    padding-bottom: 12rem;
  }
`

const Usp = styled.div`
  top: -47.5px;
  left: -47.5px;
  min-width: 150px;
`

const Mobile = styled.div`
  padding-top: 10rem;
  padding-bottom: 3rem;
`

const ImageWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.light};

  @media (min-width: 768px) {
    width: 96px;
    height: 96px;
  }

  @media (max-width: 767.98px) {
    width: 76px;
    height: 76px;
  }

  @media (max-width: 575.98px) {
    width: 46px;
    height: 46px;
  }
`

const Icon = styled(Plaatjie)`
  @media (min-width: 768px) {
    width: 58px;
    height: 58px;
  }

  @media (max-width: 767.98px) {
    width: 38px;
    height: 38px;
  }

  @media (max-width: 575.98px) {
    width: 18px;
    height: 18px;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.color.light};
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: ${({ theme }) => theme.font.weight.bold};

  @media (max-width: 767.98px) {
    font-size: ${({ theme }) => theme.font.size.small};
  }

  @media (max-width: 575.98px) {
    font-size: ${({ theme }) => theme.font.size.tiny};
  }
`

const BannerUsps: React.FC<BannerProps> = ({ fields }) => {
  const breakpoint = useMedia({ maxWidth: 767 })

  return (
    <Section className="position-relative">
      <BrandWrapper className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
        <BrandCircles />
        <div className="position-absolute d-none d-sm-block">
          {fields.usps?.map((usp, index) => {
            let x = 0
            let y = 0

            switch (index) {
              case 1:
                x = 150
                y = -260
                break
              case 2:
                x = 50
                y = -70
                break
              case 3:
                x = -205
                y = -10
                break
              case 4:
                x = -540
                y = -110
                break
              case 5:
                x = -795
                y = -372
                break
              case 6:
                x = -895
                y = -760
                break
              case 7:
                x = -820
                y = -986
                break
              default:
                x = 90
                y = -285
            }

            if (breakpoint) {
              if (index < 4) {
                x = x - 65
              }

              if (index < 2 || index > 5) {
                y = y + 75
              } else {
                y = y - 75
              }

              if (index > 3) {
                x = x + 65
              }

              if (index === 0 || index === 3) {
                x = x + 25
              }

              if (index === 4 || index === 7) {
                x = x - 25
              }
            }

            const uspAnimation = {
              x: x + index * 100,
              y: y + index * 100,
              opacity: 1,
            }

            return (
              <motion.div
                initial={{ opacity: 0 }}
                animate={uspAnimation}
                transition={{ delay: index * 0.25 }}
              >
                <Usp
                  className="position-absolute d-flex flex-column align-items-center"
                  key={index}
                >
                  <ImageWrapper className="d-flex justify-content-center align-items-center rounded-circle">
                    <Icon image={usp?.icon} alt="" />
                  </ImageWrapper>
                  <Title className="text-center mt-2">{usp?.link?.title}</Title>
                </Usp>
              </motion.div>
            )
          })}
        </div>
      </BrandWrapper>
      <Mobile className="d-flex d-sm-none flex-column position-relative">
        <div className="container">
          <div className="row">
            {fields.usps?.map((usp, index) => (
              <Usp
                className="d-flex flex-column align-items-center col-6"
                key={index}
              >
                <ImageWrapper className="d-flex justify-content-center align-items-center rounded-circle">
                  <Icon image={usp?.icon} alt="" />
                </ImageWrapper>
                <Title className="text-center mt-2">{usp?.link?.title}</Title>
              </Usp>
            ))}
          </div>
        </div>
      </Mobile>
    </Section>
  )
}

export default BannerUsps
