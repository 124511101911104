import React from 'react'
import { motion } from 'framer-motion'

interface BrandCirclesProps {}

const BrandCircles: React.FC<BrandCirclesProps> = () => {
  const brandAnimations = {
    open: {
      rotate: 360,
      transition: {
        duration: 2.5,
      },
    },
    exit: {
      rotate: 0,
    },
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 926 926"
    >
      <motion.circle
        cx="463"
        cy="463"
        r="157"
        stroke="#373737"
        strokeWidth="2"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1 }}
      />
      <motion.circle
        cx="463"
        cy="463"
        r="215.5"
        stroke="#282828"
        strokeWidth="5"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
      />
      <motion.circle
        cx="463"
        cy="463"
        r="309"
        stroke="#1B1B1B"
        strokeWidth="10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
      />
      <motion.circle
        cx="463"
        cy="463"
        r="453"
        stroke="#101010"
        strokeWidth="20"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.4 }}
      />
      <g filter="url(#filter0_d_147_2)">
        <rect width="181" height="181" x="372" y="374" fill="#fff" rx="20" />
      </g>
      <motion.path
        className="centerpoint"
        fill="#000"
        d="M447.544 455.336a86.286 86.286 0 0011.91-43.784h-27.003c0 21.112-11.363 40.804-29.659 51.392l13.526 23.385a86.766 86.766 0 0031.226-30.993zM420.269 493.239l13.537 23.374a59.384 59.384 0 0159.306-.025l13.517-23.385a86.388 86.388 0 00-86.36.037v-.001zM494.441 411.552h-27.005a86.286 86.286 0 0011.91 43.784 86.763 86.763 0 0031.229 30.989L524.1 462.94c-18.294-10.584-29.659-30.276-29.659-51.388z"
        initial={{ rotate: 0 }}
        animate={brandAnimations.open}
        transition={{
          duration: 3,
          delay: 0.5,
        }}
      />
      <defs>
        <filter
          id="filter0_d_147_2"
          width="221"
          height="221"
          x="352"
          y="354"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="10"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_147_2"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_147_2"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  )
}

export default BrandCircles
